import { Details, Divider, Summary, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import RiskAssessmentLogsDetail from './RiskAssessmentLogsDetail';

interface RiskAssessmentLogsProps {
  riskAssessmentId: string;
}

export default function RiskAssessmentLogs({
  riskAssessmentId,
}: RiskAssessmentLogsProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Details
      renderSummary={({ onClick }) => (
        <Summary onClick={onClick}>
          <Text variant="label-large">{t('documentLogsDetail.title')}</Text>
        </Summary>
      )}
    >
      <Divider component="hr" role="none" />

      <RiskAssessmentLogsDetail riskAssessmentId={riskAssessmentId} />
    </Details>
  );
}
