import { useMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  Icon,
  IconButton,
  Text,
} from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import DuplicateAcceptanceReportMutation from './graphql/DuplicateAcceptanceReport.mutation';
import {
  DuplicateAcceptanceReport,
  DuplicateAcceptanceReportVariables,
} from './graphql/__generated__/DuplicateAcceptanceReport.mutation';
import ErrorState from '../../../../../containers/ErrorState';

interface ConfirmAcceptanceReportDuplicationProps {
  open: boolean;
  title: string;
  acceptanceReportId: string;
  onRequestClose: () => void;
  onDuplicateSuccess: (duplicateOvertimeId: string) => void;
}

export default function ConfirmAcceptanceReportDuplication({
  open,
  title,
  acceptanceReportId,
  onRequestClose,
  onDuplicateSuccess,
}: ConfirmAcceptanceReportDuplicationProps): JSX.Element {
  const { t } = useTranslation();
  const [duplicate, { loading, error }] = useMutation<
    DuplicateAcceptanceReport,
    DuplicateAcceptanceReportVariables
  >(DuplicateAcceptanceReportMutation);

  const handleDuplicateButtonClick = async () => {
    if (loading) {
      return;
    }

    const { data } = await duplicate({
      variables: {
        input: {
          acceptanceReportId,
        },
      },
    });

    if (!data) {
      return;
    }

    onDuplicateSuccess(data.duplicateAcceptanceReport.id);
  };

  return (
    <>
      {error && <ErrorState error={error} />}

      <Dialog open={open} onRequestClose={onRequestClose} size="xsmall" role="alertdialog">
        <DialogHeader
          rightSlot={<IconButton onClick={onRequestClose} icon={<Icon name="close" />} />}
        >
          <Text variant="title-small">{title}</Text>
        </DialogHeader>
        <DialogBody>
          <Text variant="body-medium">
            {t('confirmAcceptanceReportDuplicationModal.description')}
          </Text>
        </DialogBody>
        <DialogActions direction="column">
          <Button onClick={onRequestClose}>
            {t('confirmAcceptanceReportDuplicationModal.cancelButton')}
          </Button>
          <Button
            color="primary"
            onClick={handleDuplicateButtonClick}
            endIcon={<Icon name="file-copy" />}
          >
            {t('confirmAcceptanceReportDuplicationModal.confirmButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
